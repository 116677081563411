import { useState, useEffect, useContext } from "react";
import { useRouter } from "next/router";
import useSWR, { SWRConfig } from "swr";

// component
import Home from "@/components/home/Home";
import SEO from "@/components/seo/SEO";
import LandingPage from "@/components/landingPage/LandingPage";

// API
import { API, graphqlOperation } from "aws-amplify";
import awsExports from "../aws-exports";
import {
  getLandingPageBannerCache,
  getShopByCategoryCache,
  getHomeScreenCollectionCache,
  getFeaturedHomeCollectionCache,
} from "@/graphql/queries";

//Content
import { generateAdwordPayload } from "@/hooks/useAnaytics";
import { compare, getShopId } from "@/utils/util.js";
import Cookies from "universal-cookie";
// contexts
import MerchantContext from "@/contexts/MerchantContext";
import { useStoreOperatingHour } from "@/contexts/StoreOperatingHourContext";
import { useAuth } from "@/contexts/AuthContext";

const HomeScreen = (props) => {
  const merchantInfoContext = useContext(MerchantContext);
  const { landingPageData, landingPageFetchStatus } = useStoreOperatingHour();
  const { isAuthenticated } = useAuth();

  // const { data } = [];
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [landingPageProductList, setLandingPageProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [featuredItems, setFeaturedItems] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  // const [landingPageData, setLandingPageData] = useState({
  //   address: "",
  //   operatingHours: [],
  // });

  const cookie = new Cookies();

  useEffect(() => {
    setIsLoading(true);
    getBannerList();
    if (!merchantInfoContext.domain.includes("ewarung.shop")) {
      getHomeScreenCollectionCacheList();
      getCategoryList();
      getFeaturedItemList();
      getPlatform();
    }
    generateAdwordPayload("/", "", merchantInfoContext?.merchantId);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const signInInfo = cookie.get(`${getShopId()}@signIn`);
    if (router.query.source === "email") {
      if (signInInfo && signInInfo.refreshToken) {
        router.push("/view-cart");
      } else {
        router.push({
          pathname: "/login",
          query: { source: "email" },
        });
      }
    }
  }, []);

  // const fetchStoreOperatingHoursAndPickUpAddress = async () => {
  //   let response = await getStoreOperatingHours(
  //     merchantInfoContext?.merchantId
  //   );
  //   if (response) setLandingPageData(response);
  // };

  //Landing product
  const getHomeScreenCollectionCacheList = async () => {
    try {
      var params = {
        merchantId: merchantInfoContext.merchantId,
      };
      const { data } = await API.graphql(
        graphqlOperation(getHomeScreenCollectionCache, params)
      );
      let collectionList =
        data?.getHomeScreenCollectionCache.collectionsAndItems;
      if (Array.isArray(collectionList)) {
        setLandingPageProductList(collectionList);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  //Banner
  const getBannerList = async () => {
    try {
      var params = {
        merchantId: merchantInfoContext.merchantId,
      };
      let res = await API.graphql(
        graphqlOperation(getLandingPageBannerCache, params)
      );
      if (res.data.getLandingPageBannerCache) {
        if (res.data.getLandingPageBannerCache.banners) {
          let data = res.data.getLandingPageBannerCache.banners;
          if (Array.isArray(data)) {
            data.sort(compare);
            setBannerList(data);
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  //Get Product
  const getFeaturedItemList = async () => {
    try {
      var params = {
        merchantId: merchantInfoContext.merchantId,
      };
      const { data } = await API.graphql(
        graphqlOperation(getFeaturedHomeCollectionCache, params)
      );
      if (data?.getFeaturedHomeCollectionCache) {
        const featuredLists =
          data?.getFeaturedHomeCollectionCache.homeCollections;
        if (Array.isArray(featuredLists)) {
          setFeaturedItems(featuredLists);
        }
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  //Category
  const getCategoryList = async () => {
    try {
      var params = {
        merchantId: merchantInfoContext.merchantId,
      };
      let { data } = await API.graphql(
        graphqlOperation(getShopByCategoryCache, params)
      );
      if (data?.getShopByCategoryCache) {
        let homeCollectionList = data?.getShopByCategoryCache.homeCollections;
        if (Array.isArray(homeCollectionList)) {
          setCategoryList(homeCollectionList);
        }
      } else {
        setCategoryList([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Warung menu redirect
  const handleMenuRedirect = () => {
    router.push("/collections-menu");
  };

  // Warung order redirect
  const handleToOrder = () => {
    const signInInfo = cookie.get(`${getShopId()}@signIn`);
    if (signInInfo && signInInfo.refreshToken && isAuthenticated) {
      router.push("/order");
    } else {
      const lastOrderId = cookie.get(`${getShopId()}@lastOrderId`);
      if (lastOrderId) {
        router.push(`/order/${lastOrderId}`);
      } else {
        router.push("/no-order");
      }
    }
  };

  // bookmark tutorial redirect
  const handleToBookmark = () => {
    router.push("/bookmark");
  };

  // get platform
  const getPlatform = () => {
    let platform = router.query.platform;
    if (platform === "tngmp") {
      // setPlatform("tngmp");
      try {
        // eslint-disable-next-line no-undef
        let res = my.getStorage({ key: "token" });
        // eslint-disable-next-line no-undef
        my.postMessage({
          action: "test",
          body: res,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      // setPlatform("ecommerce");
    }
  };

  return (
    <>
      <SEO title="Home" keywords="" description="Home" />
      {!merchantInfoContext.domain.includes("ewarung.shop") ? (
        <Home
          isLoading={isLoading}
          loading={loading}
          setLoading={setLoading}
          bannerList={bannerList}
          categoryList={categoryList}
          featuredItems={featuredItems}
          landingPageProduct={landingPageProductList}
        />
      ) : (
        <LandingPage
          bannerList={bannerList}
          landingPageData={landingPageData}
          landingPageFetchStatus={landingPageFetchStatus}
          handleMenuRedirect={handleMenuRedirect}
          handleToBookmark={handleToBookmark}
          phone={merchantInfoContext.phone}
          handleToOrder={handleToOrder}
        />
      )}
    </>
    // <pre>{JSON.stringify(data, null, 2)}</pre>
  );
};

export default HomeScreen;

// TODO
// landing page 5 col -> 3
// new section
// mobile view & desktop view
