import { useContext, useEffect } from "react";
import { ContainedButton } from "@/components/buttons/Buttons";
import { IoCallOutline } from "react-icons/io5";
import { MdListAlt } from "react-icons/md";
import { BsBookmark } from "react-icons/bs";
import Image from "next/image";

//utils
import { convertFrom24To12Format } from "@/utils/util";

//context
import { useOrder } from "@/contexts/OrderContext";
import MerchantContext from "@/contexts/MerchantContext";

import { setToastState } from "@/states/toastBarState";

function LandingPage({
  bannerList = [],
  landingPageData = {},
  landingPageFetchStatus,
  handleMenuRedirect,
  handleToBookmark,
  phone,
  handleToOrder,
}) {
  const merchantInfoContext = useContext(MerchantContext);
  const { orderType, handleChangeOrderType, tableNumber, setTableNumber } =
    useOrder();

  const availableOrderMode = [
    {
      value: "PickUp",
      name: "Pick up",
    },
    {
      value: "Delivery",
      name: "Delivery",
    },
    {
      value: "DineIn",
      name: "Dine-in",
    },
  ];

  const handleOrderType = (value) => {
    let currentValue = orderType;
    if (value !== currentValue) {
      handleChangeOrderType(value);
    }
  };

  useEffect(() => {
    const showErrorToastIfShopIsClosed = () => {
      if (
        landingPageData.warungStoreStatus !== "open" &&
        landingPageFetchStatus === "loaded"
      ) {
        setToastState({
          show: true,
          severity: "error",
          message:
            "This shop is currently closed, but you can take a look at our menu.",
          autoClose: false,
        });
      } else {
        setToastState({
          show: false,
          severity: "",
          message: "",
        });
      }
    };

    showErrorToastIfShopIsClosed();
  }, [landingPageData.warungStoreStatus, landingPageFetchStatus]);

  return (
    <>
      {/* Banner */}
      <div className="bg-gray-100 h-[200px] overflow-hidden relative">
        <div className="absolute inset-0 h-full w-full ">
          {bannerList.length > 0 && (
            <Image
              alt="image"
              layout="fill"
              objectFit="cover"
              src={process.env.BUCKET_URL + bannerList[0].homeImage}
              priority={true}
            />
          )}
        </div>
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50"></div>
        <div className="flex items-center justify-center h-full relative">
          <h1 className="text-3xl text-white tracking-wider mx-5">
            {merchantInfoContext.name}
          </h1>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center p-4">
        {/* Order Mode Selection */}
        <div className="relative left-0 bottom-12 z-10 w-full mx-6 p-2 flex flex-row justify-center">
          <div className="flex flex-row justify-center divide-x divide-solid bg-gray-200 border border-solid rounded-full w-fit text-center">
            {orderType &&
              merchantInfoContext.orderOption.length > 0 &&
              merchantInfoContext.orderOption.map((orderOption) => (
                <>
                  <ContainedButton
                    className="w-33"
                    border="rounded-full"
                    onClick={() => handleOrderType(orderOption)}
                    outlined={orderOption !== orderType}
                    selection={true}
                  >
                    <div className="flex flex-col justify-center items-center">
                      <div>
                        {
                          availableOrderMode.find(
                            (o) => o.value === orderOption
                          ).name
                        }
                      </div>
                    </div>
                  </ContainedButton>
                </>
              ))}
          </div>
        </div>

        {/* Order Mode Description */}
        <div className="relative left-0 bottom-20 z-0 h-auto w-full flex flex-col border border-white border-solid rounded-md shadow-md bg-white mx-3 px-2 py-1.5">
          <div className="flex flex-col pt-5 items-center text-center h-auto mt-2.5 mb-2">
            {orderType === "Delivery" && (
              <p>Please configure your address during order</p>
            )}
            {orderType === "PickUp" && (
              <div>
                <p>Please pickup your food at:</p>
                <p>{landingPageData?.address}</p>
              </div>
            )}
            {orderType === "DineIn" && (
              <div className="h-full flex flex-col justify-center">
                <div className="mt-5">Please enter your table number</div>
                <div>
                  <input
                    className="m-1 h-8 w-16 xs:m-2 xs:h-10 xs:w-20 border-[1px] border-[#bbb] text-center form-control rounded"
                    autoComplete="off"
                    type="text"
                    value={tableNumber}
                    onChange={(e) => setTableNumber(e.target.value)}
                    id="tableNumber"
                  />
                </div>
              </div>
            )}
            <div className="flex justify-center h-10 mt-5">
              <ContainedButton
                className="w-[252px] h-9"
                border="rounded-full"
                onClick={() => handleMenuRedirect()}
              >
                Go to Menu
              </ContainedButton>
            </div>
          </div>
        </div>
      </div>

      {/* 3 buttons */}
      <div className="w-full -mt-12 mb-4 flex justify-between px-10 sm:justify-center">
        <div className="cursor-pointer sm:m-8">
          <a href={`tel:${phone}`}>
            <div className="bg-white p-4 shadow-lg rounded-full">
              <IoCallOutline className="w-8 h-8 xs:w-12 xs:h-12 text-primary" />
            </div>
          </a>
          <div className=" flex justify-center">
            <div className="w-16 flex justify-center">
              <p className="text-center text-[10px] xs:text-[14px]">Call</p>
            </div>
          </div>
        </div>
        <div className="cursor-pointer sm:m-8" onClick={() => handleToOrder()}>
          <div className="bg-white p-4 shadow-lg rounded-full">
            <MdListAlt className="w-8 h-8 xs:w-12 xs:h-12 text-primary" />
          </div>
          <div className=" flex justify-center">
            <div className="w-16 flex justify-center">
              <p className="text-center text-[10px] xs:text-[14px]">
                View order
              </p>
            </div>
          </div>
        </div>
        <div
          className="cursor-pointer sm:m-8"
          onClick={() => handleToBookmark()}
        >
          <div className="bg-white p-4 shadow-lg rounded-full">
            <BsBookmark className="w-8 h-8 xs:w-12 xs:h-12 text-primary" />
          </div>
          <div className=" flex justify-center">
            <div className="w-16 flex justify-center">
              <p className="text-center text-[10px] xs:text-[14px]">
                Bookmark page
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Operating Hours */}
      <div className="flex flex-col justify-start items-start p-2 mx-2 mb-4 ">
        <div className="text-left font-bold mb-2">Operating Hours</div>
        {landingPageData.operatingHours?.length > 0 && (
          <div className="flex flex-row">
            <div className="w-24 flex flex-col">
              {landingPageData.operatingHours.map((item, idx) => (
                <div key={idx}>
                  <span>{item.day}</span>
                </div>
              ))}
            </div>
            <div className="flex flex-col ml-1 mr-3">
              {landingPageData.operatingHours.map((item, idx) => (
                <div key={idx}>
                  <span>{": "}</span>
                </div>
              ))}
            </div>
            <div className="flex flex-col">
              {landingPageData.operatingHours.map((item, idx) => (
                <div key={idx}>
                  <span>
                    {item.open && item.open}
                    {" - "}
                  </span>
                  <span>{item.close && item.close}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {/* To add page */}
    </>
  );
}

export default LandingPage;
