import { useRouter } from "next/router";
import Image from "next/image";
import dynamic from "next/dynamic";

// style
import classes from "./Home.module.scss";

// components
const Item = dynamic(() => import("@/components/collection/Item"));
const Featured = dynamic(() => import("@/components/home/Featured"));
const Newsletter = dynamic(() => import("@/components/newsletter/Newsletter"));
const BannerCarousel = dynamic(() =>
  import("@/components/bannerCarousel/BannerCarousel")
);

// import Newsletter from "@/components/newsletter/Newsletter";
import { ContainedButton } from "@/components/buttons/Buttons";
import { Loader } from "@/components/loader/Loader";

function Home(props) {
  const {
    isLoading,
    loading,
    setLoading,
    bannerList,
    categoryList,
    featuredItems,
    landingPageProduct,
    setItem,
  } = props;
  const router = useRouter();

  if (isLoading) return <Loader />;
  else
    return (
      <>
        {/* Banner */}
        <BannerCarousel
          bannerList={bannerList}
          loading={loading}
          setLoading={setLoading}
        />

        {/* Category */}
        {categoryList?.length > 0 && (
          <div className={classes["card-collection-container"]}>
            <div className="px-2.5 pb-[2px] bg-inherit">
              <h5 className="font-semibold my-5 m-0 xs:text-xl text-[22px">
                Shop by category
              </h5>
              <div className="flex flex-nowrap overflow-auto">
                {categoryList.map((data, index) => {
                  const imageDisplay =
                    process.env.BUCKET_URL + data.collectionIcon;
                  return (
                    <div
                      className="w-1/2 min-w-[160px] xs:max-w-auto sm:w-1/3 md:w-1/4 lg:w-1/6 p-1 flex-grow-0"
                      key={index}
                      value={data}
                    >
                      <div
                        className="bg-white rounded-[10px] p-2.5 cursor-pointer flex flex-col justify-between h-full"
                        onClick={() => {
                          router.push({
                            pathname: data.collectionSeoUrl,
                          });
                        }}
                      >
                        <h5 className="pb-2 break-words text-base m-0 font-medium leading-tight">
                          {data.title}
                        </h5>
                        <div className="relative max-w-[150px] xs:max-w-auto aspect-square">
                          <Image
                            alt={data.seoTitle}
                            layout="fill"
                            objectFit="contain"
                            src={imageDisplay}
                            title={data.title}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <Featured list={featuredItems} />

        {landingPageProduct?.map((item, idx) => {
          if (item.items.length === 0) {
            return null;
          }
          return (
            <div className="mb-5" key={idx}>
              <div className="px-2.5 pb-[2px] bg-inherit">
                <div className="flex items-center my-2.5 overflow-hidden">
                  <h5 className="text-left font-semibold flex-[6] m-0 xs:text-xl text-[22px]">
                    {item?.homeCollectionTitle}
                  </h5>
                  {item.items.length >= 4 && (
                    <div className="flex-[4] flex justify-end">
                      <ContainedButton
                        onClick={() => {
                          router.push({
                            pathname: `/${item?.productCollectionSeoUrl}`,
                          });
                        }}
                        outlined={true}
                        className="border-gray-300 uppercase h-12"
                        fontSize="text-[13px]"
                        color="text-body"
                      >
                        View All
                      </ContainedButton>
                    </div>
                  )}
                </div>
                {/* TODO: Can't fix itemPerPage to 4. It only works on desktop */}
                {item.items.length > 0 && (
                  <Item
                    className="h-[164px] overflow-auto"
                    itemList={item.items}
                    handleItemClick={(selectedItem) => setItem(selectedItem)}
                    itemPerPage={4}
                  />
                )}
              </div>
            </div>
          );
        })}

        {/* Newsletter */}
      </>
    );
}

export default Home;
